import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import VerticalSectionTitle from "./verticalSectionTitle"
import AdvancedMD from "../../images/svg/inline-svg/icon-advancedmd.svg"
import AlterG from "../../images/svg/inline-svg/icon-alterg.svg"
import Apta from "../../images/svg/inline-svg/icon-apta.svg"
import Athletico from "../../images/svg/inline-svg/icon-athletico.svg"
import BetterHealthcare from "../../images/svg/inline-svg/icon_better-healthcare.svg"
import Blazepod from "../../images/svg/inline-svg/icon_black_blazepod.svg"
import BmsBusinessClinicalManagement from "../../images/svg/inline-svg/icon-bcms-business-clinical-management-services.svg"
import Behavr from "../../images/svg/inline-svg/icon-behavr-ex.svg"
import BetterPt from "../../images/svg/inline-svg/icon-betterpt-hss.svg"
import Breakthrough from "../../images/svg/inline-svg/icon-breakthrough-pt.svg"
import CallHero from "../../images/svg/inline-svg/icon_black_call-hero.svg"
import ChangeHealthcare from "../../images/svg/inline-svg/icon-change-healthcare.svg"
import Cmf from "../../images/svg/inline-svg/icon-cmf-group.svg"
import Fyzical from "../../images/svg/inline-svg/icon-fyzical.svg"
import GrayInstitute from "../../images/svg/inline-svg/icon-gray-institute.svg"
import HandsOnDiagnosis from "../../images/svg/inline-svg/icon-hands-on-diagnostics.svg"
import HealthAndRehabSolutions from "../../images/svg/inline-svg/icon-health-and-rehab-solutions-hrs.svg"
import HealthyPostureClub from "../../images/svg/inline-svg/icon_black_healthy-posture-club.svg"
import Hydroworkx from "../../images/svg/inline-svg/icon-hydroworx.svg"
import Hyperice from "../../images/svg/inline-svg/icon-hyperice.svg"
import Kinotek from "../../images/svg/inline-svg/icon_black_kinotek.svg"
import Kno2 from "../../images/svg/inline-svg/icon-kno2.svg"
import Lightforce from "../../images/svg/inline-svg/icon-lightforce-therapy-lasers.svg"
import LunaPt from "../../images/svg/inline-svg/icon-luna-pt.svg"
import MyPtInsurance from "../../images/svg/inline-svg/icon-mypt-insurance.svg"
import Nelson from "../../images/svg/inline-svg/icon-nelson-cpas.svg"
import Neufit from "../../images/svg/inline-svg/icon_black_neufit.svg"
import NeuroRehabVrCutout from "../../images/svg/inline-svg/icon-neuro-rehab-vr-cutout.svg"
import NoveraPayment from "../../images/svg/inline-svg/icon-novera-payment-solutions.svg"
import OneStep from "../../images/svg/inline-svg/icon_black_one-step.svg"
import Parable from "../../images/svg/inline-svg/icon_black_parable-associates.svg"
import PatientPay from "../../images/svg/inline-svg/icon_black_patientpay.svg"
import PerformanceHealth from "../../images/svg/inline-svg/icon_black_performance-health.svg"
import PysioTec from "../../images/svg/inline-svg/icon-physiotec.svg"
import PracticeFreedom from "../../images/svg/inline-svg/icon-practice-freedom-u.svg"
import PracticePromotions from "../../images/svg/inline-svg/icon-practice-promotions.svg"
import PredictionHealth from "../../images/svg/inline-svg/icon-predictionhealth.svg"
import PVerify from "../../images/svg/inline-svg/icon-pverify.svg"
import RPAcademy from "../../images/svg/inline-svg/icon_black_rehab2perform-academy.svg"
import ReliableItHealthcare from "../../images/svg/inline-svg/icon-reliable-it-healthcare.svg"
import Rmbs from "../../images/svg/inline-svg/icon-rmbs-rehab-medical-billing-services.svg"
import RockTape from "../../images/svg/inline-svg/icon-rocktape.svg"
import SpartaScience from "../../images/svg/inline-svg/icon_black_sparta-science.svg"
import StopPain from "../../images/svg/inline-svg/icon-stopain.svg"
import TherapyRevenueSolutions from "../../images/svg/inline-svg/icon-therapy-revenue-solutions.svg"
import VantageClinicalSolutions from "../../images/svg/inline-svg/icon-vantage-clinical-solutions.svg"
import VirtualSally from "../../images/svg/inline-svg/icon_black_virtual-sally.svg"
import WebPt from "../../images/svg/inline-svg/webpt-logo-primary-black.svg"
import WelcomeWare from "../../images/svg/inline-svg/icon-welcomeware.svg"
import WorldPay from "../../images/svg/inline-svg/icon-worldpay.svg"

const SectionPastSponsors = ({
  headingTag: HeadingTag = "p",
  sectionHeading,
  sectionHeadingMobile,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              year
            }
          }
        }
      `}
      render={(data) => {
        return (
          <StyledSection>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <StyledVerticalSectionTitle>
                  <VerticalSectionTitle
                    svg={sectionHeading}
                    mobileSvg={sectionHeadingMobile}
                  />
                </StyledVerticalSectionTitle>
                <LogoGrid>
                  <LogoContainer>
                    <AdvancedMD />
                  </LogoContainer>
                  <LogoContainer>
                    <AlterG />
                  </LogoContainer>
                  <LogoContainer>
                    <Apta />
                  </LogoContainer>
                  <LogoContainer>
                    <Athletico />
                  </LogoContainer>
                  <LogoContainer>
                    <BmsBusinessClinicalManagement />
                  </LogoContainer>
                  <LogoContainer>
                    <Behavr />
                  </LogoContainer>
                  <LogoContainer>
                    <BetterHealthcare />
                  </LogoContainer>
                  <LogoContainer>
                    <BetterPt />
                  </LogoContainer>
                  <LogoContainer>
                    <Blazepod />
                  </LogoContainer>
                  <LogoContainer>
                    <Breakthrough />
                  </LogoContainer>
                  <LogoContainer>
                    <CallHero />
                  </LogoContainer>
                  <LogoContainer>
                    <ChangeHealthcare />
                  </LogoContainer>
                  <LogoContainer>
                    <Cmf />
                  </LogoContainer>
                  <LogoContainer>
                    <Fyzical />
                  </LogoContainer>
                  <LogoContainer>
                    <GrayInstitute />
                  </LogoContainer>
                  <LogoContainer>
                    <HandsOnDiagnosis />
                  </LogoContainer>
                  <LogoContainer>
                    <HealthAndRehabSolutions />
                  </LogoContainer>
                  <LogoContainer>
                    <HealthyPostureClub />
                  </LogoContainer>
                  <LogoContainer>
                    <Hydroworkx />
                  </LogoContainer>
                  <LogoContainer>
                    <Hyperice />
                  </LogoContainer>
                  <LogoContainer>
                    <Kinotek />
                  </LogoContainer>{" "}
                  <LogoContainer>
                    <Kno2 />
                  </LogoContainer>
                  <LogoContainer>
                    <Lightforce />
                  </LogoContainer>
                  <LogoContainer>
                    <LunaPt />
                  </LogoContainer>
                  <LogoContainer>
                    <MyPtInsurance />
                  </LogoContainer>
                  <LogoContainer>
                    <Nelson />
                  </LogoContainer>
                  <LogoContainer>
                    <Neufit />
                  </LogoContainer>
                  <LogoContainer>
                    <NeuroRehabVrCutout />
                  </LogoContainer>
                  <LogoContainer>
                    <NoveraPayment />
                  </LogoContainer>
                  <LogoContainer>
                    <OneStep />
                  </LogoContainer>
                  <LogoContainer>
                    <Parable />
                  </LogoContainer>
                  <LogoContainer>
                    <PatientPay />
                  </LogoContainer>
                  <LogoContainer>
                    <PerformanceHealth />
                  </LogoContainer>
                  <LogoContainer>
                    <PysioTec />
                  </LogoContainer>
                  <LogoContainer>
                    <PracticeFreedom />
                  </LogoContainer>
                  <LogoContainer>
                    <PracticePromotions />
                  </LogoContainer>
                  <LogoContainer>
                    <PredictionHealth />
                  </LogoContainer>
                  <LogoContainer>
                    <PVerify />
                  </LogoContainer>
                  <LogoContainer>
                    <RPAcademy />
                  </LogoContainer>
                  <LogoContainer>
                    <ReliableItHealthcare />
                  </LogoContainer>
                  <LogoContainer>
                    <Rmbs />
                  </LogoContainer>
                  <LogoContainer>
                    <RockTape />
                  </LogoContainer>
                  <LogoContainer>
                    <SpartaScience />
                  </LogoContainer>
                  <LogoContainer>
                    <StopPain />
                  </LogoContainer>
                  <LogoContainer>
                    <TherapyRevenueSolutions />
                  </LogoContainer>
                  <LogoContainer>
                    <VantageClinicalSolutions />
                  </LogoContainer>
                  <LogoContainer>
                    <VirtualSally />
                  </LogoContainer>
                  <LogoContainer>
                    <WebPt />
                  </LogoContainer>
                  <LogoContainer>
                    <WelcomeWare />
                  </LogoContainer>
                  <LogoContainer>
                    <WorldPay />
                  </LogoContainer>
                </LogoGrid>
              </div>
            </div>
          </StyledSection>
        )
      }}
    />
  )
}

export default SectionPastSponsors

const StyledSection = styled.section`
  padding: 100px 0 50px;
`
const StyledVerticalSectionTitle = styled.div``
const LogoGrid = styled.div.attrs({
  className: "small-12 large-10 cell",
})`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1024px) {
    justify-content: center;
  }
  @media screen and (max-width: 640px) {
  }
`
const LogoContainer = styled.div`
  width: 20%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: 33%;
  }
  @media screen and (max-width: 640px) {
    width: 50%;
  }
`
