import React from "react"
import styled from "styled-components"
import IconAudience from "../../images/svg/inline-svg/icon-audience.svg"
import IconBrandAwareness from "../../images/svg/inline-svg/icon-brandawareness.svg"
import IconConnect from "../../images/svg/inline-svg/icon-connect.svg"
import IconGrowth from "../../images/svg/inline-svg/icon-growth.svg"

const SectionTopFour = () => (
  <StyledSection>
    <div className="grid-container">
      <div className="grid-x grid-padding-x top-three-cards">
        <SectionHeadingContainer>
          <h2>
            Sponsor
            <br className="show-for-small-only" /> Benefits
          </h2>
        </SectionHeadingContainer>
        <HighlightCard>
          <IconHolder>
            <IconAudience />
          </IconHolder>
          <Body>
            Engage with a highly
            <br className="show-for-medium" />
            qualified audience.
          </Body>
        </HighlightCard>
        <HighlightCard>
          <IconHolder>
            <IconBrandAwareness />
          </IconHolder>
          <Body>
            Build brand awareness while
            <br className="show-for-medium" /> educating key industry leaders.
          </Body>
        </HighlightCard>
        <HighlightCard>
          <IconHolder>
            <IconConnect />
          </IconHolder>
          <Body>
            Connect with prospects, customers, influencers, and decision-makers.
          </Body>
        </HighlightCard>
        <HighlightCard>
          <IconHolder>
            <IconGrowth />
          </IconHolder>
          <Body>
            Generate new qualified leads and develop new partnerships to drive
            growth.
          </Body>
        </HighlightCard>
      </div>
    </div>
  </StyledSection>
)

export default SectionTopFour

const StyledSection = styled.section`
  padding: 90px 0;
  background-color: #f9fafc;
  @media screen and (max-width: 1024px) {
    padding: 60px 0;
  }
  @media screen and (max-width: 640px) {
  }
`

const HighlightCard = styled.div.attrs({
  className: "small-12 medium-3 cell",
})`
  @media screen and (max-width: 1024px) {
    margin-top: 30px;
  }
`

const Body = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
`
const SectionHeadingContainer = styled.div.attrs({
  className: "small-12 cell",
})`
  * {
    font-size: 64px;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-weight: 900;
    text-align: center;
    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 640px) {
      font-weight: 900;
      font-size: 38px;
      line-height: 130%;
      text-align: justify;
    }
  }
`
const IconHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 50px;
  margin-bottom: 20px;
`
