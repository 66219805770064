import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import VerticalSectionTitle from "./verticalSectionTitle"

const SectionSponsorTypes = ({
  headingTag: HeadingTag = "p",
  sectionHeading,
  sectionHeadingMobile,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          image1: file(relativePath: { eq: "SponsorTypes-01.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image2: file(relativePath: { eq: "SponsorTypes-02.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image3: file(relativePath: { eq: "SponsorTypes-03.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image4: file(relativePath: { eq: "SponsorTypes-04.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const image1 = data.image1.childImageSharp.fluid
        const image2 = data.image2.childImageSharp.fluid
        const image3 = data.image3.childImageSharp.fluid
        const image4 = data.image4.childImageSharp.fluid

        return (
          <StyledSection>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <StyledVerticalSectionTitle>
                  <VerticalSectionTitle
                    svg={sectionHeading}
                    mobileSvg={sectionHeadingMobile}
                  />
                </StyledVerticalSectionTitle>
                <div className="small-12 medium-10 cell">
                  <RowCard>
                    <div className="grid-x grid-padding-x">
                      <div className="small-12 medium-6 cell">
                        <BigTitle>Exhibit</BigTitle>
                        <SmallTitle>Looking for maximum impact?</SmallTitle>
                        <Body>
                          You’ll want one of our exhibitor packages. Put your
                          brand on full display for the entire conference in
                          Ascend Central, the high-traffic area that serves as
                          the hub for all of Ascend.
                        </Body>
                      </div>
                      <ImgContainer>
                        <Img fluid={image1} />
                      </ImgContainer>
                    </div>
                  </RowCard>
                  <RowCard>
                    <div className="grid-x grid-padding-x">
                      <div className="small-12 medium-6 cell">
                        <BigTitle>Activations</BigTitle>
                        <SmallTitle>
                          Hoping to surprise and delight attendees?
                        </SmallTitle>
                        <Body>
                          We’ve provided several experiential activation options
                          that will help attendees engage with your brand in a
                          unique and fun way!
                        </Body>
                      </div>
                      <ImgContainer>
                        <Img fluid={image2} />
                      </ImgContainer>
                    </div>
                  </RowCard>
                  <RowCard>
                    <div className="grid-x grid-padding-x">
                      <div className="small-12 medium-6 cell">
                        <BigTitle>Promotional and Branding</BigTitle>
                        <SmallTitle>
                          Want to make sure every attendee goes home with your
                          brand?
                        </SmallTitle>
                        <Body>
                          We’ve got you covered. Our promotional and branding
                          sponsorship options will ensure your brand goes home
                          with every attendee.
                        </Body>
                      </div>
                      <ImgContainer>
                        <Img fluid={image3} />
                      </ImgContainer>
                    </div>
                  </RowCard>
                  <RowCard>
                    <div className="grid-x grid-padding-x">
                      <div className="small-12 medium-6 cell">
                        <BigTitle>Your Unique Ideas</BigTitle>
                        <SmallTitle>
                          Is there something you really want to see?
                        </SmallTitle>
                        <Body>
                          An activation you’d like to bring to life? Let us know
                          and we can work with your company to customize a
                          feasible option.
                        </Body>
                      </div>
                      <ImgContainer>
                        <Img fluid={image4} />
                      </ImgContainer>
                    </div>
                  </RowCard>
                </div>
              </div>
            </div>
          </StyledSection>
        )
      }}
    />
  )
}

export default SectionSponsorTypes

const StyledSection = styled.section`
  padding: 100px 0 50px;
`
const StyledVerticalSectionTitle = styled.div``
const BigTitle = styled.p`
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
`
const SmallTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  margin-bottom: 0;
`
const Body = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
`
const RowCard = styled.div.attrs({
  className: "grid-container",
})`
  margin-bottom: 20px;
`
const ImgContainer = styled.div.attrs({
  className: "small-12 medium-5 medium-offset-1 ",
})``
